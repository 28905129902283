body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}
