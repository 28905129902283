.container {
  margin: 0 auto;
  text-align: left;
  max-width: 100%;
  align-items: left;
}

.project-container {
  padding: 1rem;
  text-align: left;
}

.description {
  max-width: 90%;
  text-overflow: inherit;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 4rem;
}
.sub-title {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 2rem;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2rem;
    line-height: 3rem;
  }
  .sub-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
