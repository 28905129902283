.social-links {
  display: flex;
  margin-right: 0.5rem;
  justify-content: space-around;
  width: 90%;
}
.social-button {
  border: 1px solid var(--text-primary);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: var(--background);
  text-decoration: none;
  color: var(--color-primary);
  fill: rgba(17, 20, 24, 0.94);
  display: flex;
  width: 8rem;
}
.social-icon {
  margin-right: 0.5rem;
}
