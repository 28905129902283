.sliderStyles {
  height: 100%;
  max-width: 480px;
  position: relative;
}
.slideStyles {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: var(--background);
  color: var(--text-primary);
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff;
  cursor: pointer;
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: -25px;
  background-color: var(--background);
  color: var(--text-primary);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff;
  cursor: pointer;
}
